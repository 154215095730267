


import FETCHING from '../../library/fetching'
import DATAMASTER from '../../library/dataMaster'
import UMUM from '../../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        program_kode : '',
        kegiatan_kode : [],
        unit_kerja : '',
        tahun : '',
        kegiatan_id : '',
      },
      kegiatanSub : {
        kegiatan_sub_kode : [],
        unit_kerja : '',
        tahun : '',
        kegiatan_sub_id : '',
        sumber_dana : '',
        uraian : '',
        kode_full  : '',
        master_sumber_dana_uraian : '',

      },

      tag : {
        id : '',
        kegiatan_sub_kode : '',
        uraian : '',
        keterangan : '',
      },
      label : {
        id : '',
        kegiatan_sub_tag_id : '',
        uraian : '',
        rincian : [{
          uraian : '',
          koefisien : '',
          satuan : '',
          harga : 0,
          ppn : 0,
          jml : 0,
        }],
      },
      rincian : {
        id : '',
        kegiatan_sub_label_id : '',
        kegiatan_sub_kode : '',
        uraian : '',
        koefisien : '',
        satuan : '',
        harga : 0,
        ppn : 0,
        jml : 0,
      },

      target : {
        id : 0,
        rincian_id : 0,
        jan : 0,
        feb : 0,
        mar : 0,
        apr : 0,
        mei : 0,
        jun : 0,
        jul : 0,
        agu : 0,
        sep : 0,
        okt : 0,
        nov : 0,
        des : 0,
        thn : 0,
        status : 0,
      },


      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
      filterku : {
          program_kode : '',
          tahun : '',
          unit_kerja : '',
          instansi : '',
      },

      list_data : [],
      list_label : [],

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : true,

      mdl_view_sub_kegiatan_rincian : false,
      mdl_add_sub_kegiatan_rincian : false,
      mdl_edit_sub_kegiatan_rincian : false,
      mdl_hapus_sub_kegiatan_rincian : false,

      FETCHING : FETCHING,
      DATAMASTER : DATAMASTER,
      UMUM : UMUM,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.CT_KEGIATAN + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit : this.page_limit,
              instansi : this.filterku.instansi,
              unit_kerja : this.filterku.unit_kerja,
              tahun : this.filterku.tahun,
              program_kode : this.filterku.program_kode,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function() {
      fetch(this.$store.state.url.CT_KEGIATAN + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.CT_KEGIATAN + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.CT_KEGIATAN + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){

        console.log(data);
        this.form.kegiatan_kode = data.kode_full;
    },

    selectKegiatan : function(data){
      console.log(data)
      this.form.kegiatan_id = data.kegiatan_id
      DATAMASTER.postMasterkegiatanSub(data.kode_full)
    },

    selectSubKegiatan : function(data){

      console.log(data)
        this.kegiatanSub.tahun = data.tahun ;
        this.kegiatanSub.kegiatan_sub_id = data.kegiatan_sub_id ;

        this.tag.kegiatan_sub_kode = data.kode_full;
    },

    selectTag : function(data, data1){
        this.tag.id = data.id ;
        this.tag.kegiatan_sub_kode = data.kegiatan_sub_kode ;
        this.tag.uraian = data.uraian ;
        this.tag.keterangan = data.keterangan ;

        this.label.kegiatan_sub_tag_id = data.id;
        this.label.kegiatan_sub_kode = data1.kode_full


        // this.viewLabel(this.tag.id)
    },


    selectLabel : function(data, data2, data1){

      this.label.id = data.id ; 
        this.label.kegiatan_sub_tag_id = data.kegiatan_sub_label_id ; 
        this.label.uraian = data.uraian ; 

        this.label.kegiatan_sub_tag_id = data2.id;
        this.label.kegiatan_sub_kode = data1.kode_full
        this.label.rincian = data.rincian ; 


        this.rincian.kegiatan_sub_label_id = data.id
        this.rincian.kegiatan_sub_kode = data1.kode_full

        // console.log(data);
        // console.log(data1);
        // console.log(data2);

    },

    

    selectRincian : function(data, data3, data2, data1){
      
      console.log(data);
      console.log(data3);
      console.log(data2);
      console.log(data1);

        this.rincian.id = data.id ; 
        this.rincian.kegiatan_sub_label_id = data.kegiatan_sub_label_id ; 
        this.rincian.kegiatan_sub_kode = data.kegiatan_sub_kode ; 
        this.rincian.uraian = data.uraian ; 
        this.rincian.koefisien = data.koefisien ; 
        this.rincian.satuan = data.satuan ; 
        this.rincian.harga = data.harga ; 
        this.rincian.ppn = data.ppn ; 
        this.rincian.jml = data.jml ; 

        this.target.rincian_id = data.id


        this.kegiatanSub.uraian = data1.uraian
        this.kegiatanSub.kode_full = data1.kode_full
        this.kegiatanSub.master_sumber_dana_uraian = data1.master_sumber_dana_uraian


        this.tag.uraian = data2.uraian
        this.label.uraian = data3.uraian


        this.viewTarget();
    },

    pushRuncian : function(){
      this.label.rincian.push({
        kegiatan_sub_label_id : '',
        kegiatan_sub_kode : '',
        uraian : '',
        koefisien : '',
        satuan : '',
        harga : 0,
        ppn : 0,
        jml : 0,
      })
    },



    viewTarget : function(){
      // this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.CT_TARGET_KEUANGAN + "getOne", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.target)
      })
          .then(res => res.json())
          .then(res_data => {
              console.log(res_data);

              this.target.jan = res_data[0].jan
              this.target.feb = res_data[0].feb
              this.target.mar = res_data[0].mar
              this.target.apr = res_data[0].apr
              this.target.mei = res_data[0].mei
              this.target.jun = res_data[0].jun
              this.target.jul = res_data[0].jul
              this.target.agu = res_data[0].agu
              this.target.sep = res_data[0].sep
              this.target.okt = res_data[0].okt
              this.target.nov = res_data[0].nov
              this.target.des = res_data[0].des

      });
    },


    addTarget : function(){
      fetch(this.$store.state.url.CT_TARGET_KEUANGAN + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.target)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.kegiatanSub.kegiatan_sub_kode = [];
          this.getView();
      });

      // console.log(this.label.rincian);
    },



    selectTarget : function(data){
      this.target.rincian_id = data.rincian_id ;
        this.target.jan = data.jan ;
        this.target.feb = data.feb ;
        this.target.mar = data.mar ;
        this.target.apr = data.apr ;
        this.target.mei = data.mei ;
        this.target.jun = data.jun ;
        this.target.jul = data.jul ;
        this.target.agu = data.agu ;
        this.target.sep = data.sep ;
        this.target.okt = data.okt ;
        this.target.nov = data.nov ;
        this.target.des = data.des ;
        this.target.thn = data.thn ;
        this.target.status = data.status ;
    },

    countMount : function(data){
      var datax = parseFloat(data.jan) + 
      parseFloat(data.feb) + 
      parseFloat(data.mar) + 
      parseFloat(data.apr) + 
      parseFloat(data.mei) + 
      parseFloat(data.jun) + 
      parseFloat(data.jul) + 
      parseFloat(data.agu) + 
      parseFloat(data.sep) + 
      parseFloat(data.okt) + 
      parseFloat(data.nov) + 
      parseFloat(data.des) 

      return datax

    },


    // ====================================== CONTOH eDOC ====================================
    onChangex(data){
      // await DATAMASTER.postMasterUrusanBidang(val)
    },

    async AsyncKegiatan(){
      DATAMASTER.postMasterkegiatan(this.form.program_kode)
    },

    async onChangexInstansi(){
        await DATAMASTER.getInstansiAsync("");
        await this.onChangexUnitKerja();
      },
    async onChangexUnitKerja(){
      var unitkerjax = await DATAMASTER.getUnitKerjaAsync(this.filterku.instansi)
      console.log(unitkerjax[0]);
      this.filterku.unit_kerja = unitkerjax[0].id
      console.log(this.filterku.unit_kerja);
      this.getView();
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_sumber_dana_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {DATAMASTER.postMasterSumberDanaAutoComplete(val)}
        })
    },
    // ====================================== CONTOH AUTOCOMPLETE ====================================

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },


    // ====================================== PAGINATE ====================================

  },

  mounted () {


    let profilex = JSON.parse(localStorage.profile);
    let profile = profilex.profile;
    this.form.unit_kerja = profile.unit_kerja;
    this.kegiatanSub.unit_kerja = profile.unit_kerja;
    
    this.filterku.instansi = profile.instansi_id;
    this.filterku.unit_kerja = profile.unit_kerja;


    const d = new Date();
    let year = d.getFullYear();

    this.form.tahun = year;
    this.filterku.tahun = year;
    this.kegiatanSub.tahun = year;
    this.target.thn = year;

    // console.log(year)


    DATAMASTER.postProgram(this.form.unit_kerja)
    DATAMASTER.postMasterSumberDanaAutoComplete('')
    DATAMASTER.getTahun();





    FETCHING.getContohAtocomplete('')
  //   DATAMASTER.postMasterUrusan('')
    // this.getView();
    this.onChangexInstansi(); 
  },
}
